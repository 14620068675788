import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApidocsService } from 'src/app/services/apidocs.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(private router: Router, private apidocsService: ApidocsService) {

  }
  apidocs: any
  logindoc: any
  ngOnInit() {
    this.apidocsService.getApidocs().subscribe(data => {
      this.apidocs = data;
    })
  }

  navigateToContactUs(divId) {
    this.router.navigate(['help'], { fragment: divId })
  }

  getDoc(key) {  
    return this.apidocs.records.filter(elem => elem.key === key)[0]
  }
}
