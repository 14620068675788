import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../services/auth.service'
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public jwtHelper: JwtHelperService, public authService: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var jwtRenewal = false;
    if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'undefined') {
      if (this.jwtHelper.isTokenExpired()) {
        jwtRenewal = true;
        this.authService.requestToken(localStorage.getItem('access_token')).then(res => {
          if (res === undefined) {
            this.authService.logout().subscribe(data => {
              const promise = new Promise((resolve, reject) => {
                localStorage.removeItem('access_token')
                resolve();
              });
              promise.then((res) => {
                this.router.navigate(['auth']);
              });
            });
            return false;
          } else {
            localStorage.setItem('access_token', res)
            this.router.navigate([state.url]);
            return true;
          }
        })
      } else {
        return true;
      }
    }
    
    if (next.queryParams.nonce) {
      this.router.navigate(['auth'], { queryParams: { nonce: next.queryParams.nonce, url: state.url } });
      return true;
    }
    // this is the case when there is no access_token in browser's local storage
    if (!jwtRenewal) {
      this.router.navigate(['auth'], {queryParams: {url: state.url}});
      return false;
    }
  }


}
